import dynamic from "next/dynamic";
import { CardWithCtaWrapper } from "./styles";

const Link = dynamic(() => import("../link/link"));

export const CardWithCta = ({
  title,
  description,
  cta,
  type = "buttonPrimary",
}) => {
  return (
    <CardWithCtaWrapper>
      {title && <span>{title}</span>}
      {description && <p>{description}</p>}
      {cta && <Link type={type} {...cta} />}
    </CardWithCtaWrapper>
  );
};

export default CardWithCta;
