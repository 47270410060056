import styled from "styled-components";

export const CardWithCtaWrapper = styled.div`
  display: flex;
  height: 320px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: var(--radiusL);
  background-color: var(--gray100);
  padding: var(--spacingXs) var(--spacingM);
  gap: var(--spacingS);

  > span {
    text-align: center;
    font-size: var(--textL);
    line-height: var(--lineHeightL);
  }

  > p {
    text-align: center;
    font-size: var(--textS);
    line-height: var(--lineHeightS);
  }

  > a {
    width: 100%;
  }

  @media screen and (min-width: 1128px) {
    padding: var(--spacingXs) 120px;
  }
`;
